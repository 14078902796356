.a {
    padding: 50px 50px 30px 50px;
    display: flex;
    flex-direction: column;
}

.a-row {
    padding: 0px 40px 50px 40px;
    display: flex;
    background: #2e2e2e;
    border-radius: 20px;
    flex-direction: column;
}

.a-header {
    display: flex;
}
.header-title {
    display: flex;
    flex-direction: column;
}

.header-title h3{
    font-size: 34px;
    font-weight: 500;
    margin: 0;
    padding: 20px 0px 0px 0px;
    border-top: 5px solid #ffc732;
}

.e-desc {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.a-left {
    display: flex;
    flex: 1;
}

.a-right {
    display: flex;
    flex: 1;
}

.s {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}
.s-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}

.s-progress {
    display: flex;
    flex-direction: column;
    width: calc(40.33% - 10px);
    justify-content: center;
    padding: 10px 20px 10px 0px;
}

.skill-info h4 {
    font-size: 17px;
    font-weight: 300;
    margin: 10px 0px;
}

.skill-info span {
    font-size: 14px;
    font-weight: 500;
}

.progress {
    position: relative;
    display: block;
    width: 100%;
    height: 6px;
    background-color: #e0e0e0;
    border-radius: 6px;
    overflow: hidden;
}

.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #e90093;
    transition: width 0.5s ease-in-out;
}

.e {
    width: 100%;
}

.e-experience {
    position: relative;
    padding: 70px 0px;
}

.e-timeline {
    position: absolute;
    top: 0;
    left: calc(20% + 2px);
    bottom: 0;
    width: 1px;
    background:#ffc732;
}

.e-timeline::before, .time-line::after {
    content: '';
    display: block;
    border-radius: 100%;
    background-color: #ffc732;
    position: absolute;
    left: -3px;
    height: 7px;
    width: 7px;
}

.e-timeline::before {
    top: 0;
}

.e-timeline::after {
    bottom: 0;
}

.e-timeline::after {
    content: '';
    display: table;
    clear: both;
}

.e-title span {
    font-size: 16px;
    font-weight: 300;
}

.e-entry {
    clear: both;
    position: relative;
}


.e-entry:last-of-type .e-body {
    margin-bottom: 0;
}


.e-entry .e-title {
    float: left;
    width: 20%;
    position: relative;
}

.e-entry .e-title::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border: 1px solid #ffc732;
    background-color: #ffc732;
    border-radius: 100%;
    padding: 4.5px;
    top: 15%;
    right: -10px;
    z-index: 1;
}

.e-entry .e-body {
    float: right;
    width: 73%;
    margin-bottom: 30px;
}
.e-entry .e-body h4 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 17px;
}

.e-entry .e-body p {
    margin: 0;
    font-weight: 300;
    font-size: 15px;
}


@media screen and (max-width: 768px) {
    .a {
        padding: 50px 20px 30px 20px;
    }

    .a-row {
        padding: 0px 20px 30px 20px;
    }

    .e-experience {
        position: relative;
        padding: 40px 0px;
    }

    .s-progress {
        width: calc(47.33% - 10px);
        padding: 10px 8px;
    }

    .e {
        margin-top: 50px;
    }

    .e-entry .e-title {
        width: 32%;
    }

    .e-entry .e-body {
        width: 60%;
    }
    .e-timeline {
        position: absolute;
        left: calc(32% + 2px);
    }
    
    .e-desc {
        flex-direction: column;
    }
}


