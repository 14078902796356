.modalShow {
    width: 100%;
    height: 100%;
    background: #1e221f;
    position: fixed;
    top: 0;
    right:0;
    transition: 0.5s;
    overflow-y: scroll;
    z-index: 1000;
}

.modal-dialog {
    padding: 50px 30px;
}

.btn-close {
    height: 50px;
    width: 50px;
    background-color: #ffc732;
    border-radius: 0px 6px 6px 0px;
    border: none;
    position: absolute;
    top: 10px;
    left: 0;
}


.btn-close:after {
    position: absolute;
    top: 26px;
    bottom: 0;
    left: 0;
    right: 0;
    content: "\D7";
    font-size: 38px; 
    color: #535353;
    line-height: 0px;
    text-align: center;
}

.modal-content {
    margin-top: 40px;
}

.m-logo {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items:center;
    margin-bottom: 20px;
}

.m-logo img {
    height: 16px;
    display: flex;
    justify-content: center;
    align-items:center;
    padding: 10px 10px;
        border-radius: 6px;
        border: 1px solid #909090;
    }

.m-title {
    border-bottom: 1px solid #909090;
    padding: 0px 0px 20px 0px;
}

.m-title-a {
    font-size: 30px;
    font-weight: bold;
}

.m-title-b {
    font-size: 18px;
    font-weight: 300;
}

.m-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 50%;
    padding: 30px 0px;
    border-bottom: 1px solid #909090;
}

.m-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50%;
    padding: 20px 20px;
}

.m-menu a {
    color: #fff;
    text-decoration: none;
    font-weight: 300;;
    display: flex;
    align-items: center;
    justify-content: center;
}

.m-menu-icon {
    color: #fff;
    margin-right: 20px;
    font-size: 24px;
    font-weight: 300;
}

.m-footer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 0px;
}

.m-footer span {
    font-weight: 300;
    font-size: 14px;
}