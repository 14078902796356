.n {
    display: flex;
    position: relative;
    align-items: center;
    background: #272727;
}

.navBar-left {
    flex: 1;
    display: flex;
    padding: 24px 0px 24px 0px;
}

.navBar-logo {
    height: 30px;
    padding: 10px;
    border: 1px solid #909090;
    border-radius: 6px;
    transition: 0.3s
}

.navBar-logo-sticky {
    height: 25px;
    padding: 10px;
    border: 1px solid #909090;
    border-radius: 6px;
    transition: 0.3s
}

@media screen and (max-width: 768px) {
    .navBar-logo-sticky {
        border-radius: 0px 6px 6px 0px;
        border-top: 1px solid #909090;
        border-right: 1px solid #909090;
        border-bottom: 1px solid #909090;
        border-left: none;
        height: 30px;
}
}

.navBar-right {
    flex: 1;
    display: flex;
    padding: 24px 0px 24px 0px;
    align-items: center;
    justify-content: flex-end;
}


.navi ul {
    padding:0;
    margin: 0;
}

.navi-item {
    float: left;
    list-style: none;
    padding: 8px 20px;
}

.navi-item a {
    color: #fff;
    text-decoration: none;
    align-items: center;
    padding: 8px 10px;
    transition: margin-right 2s ease-in-out .5s;
    font-weight: 300;
}

.toggle-menu {
    display: none;
}

.navi-item a:hover {
    border-bottom: 1px solid #fedd2b;
    color: #fedd2b;
}

@media screen and (min-width: 768px) {
    .naviB {
        display: flex;
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .navBar {
        padding: 10px 10px;
        position: fixed;
        width: 100%;
        align-items: center;
    }

    .navBar-logo {
        border-radius: 0px 6px 6px 0px;
        border-top: 1px solid #909090;
        border-right: 1px solid #909090;
        border-bottom: 1px solid #909090;
        border-left: none;
    }
    
    .navBar-left {
        padding: 10px 0px;
    }

    .naviA {
        display: none;
    }

    .naviB {
        display: none;
    }

    .toggle-menu-top {
        display: flex;
        position: fixed;
        width: 50px;
        height: 50px;
        top: 10px;
        right: 0;
        justify-content: center;
        align-items: center;
        background: #272727;
        border-top: 1px solid #909090;
        border-left: 1px solid #909090;
        border-bottom: 1px solid #909090;
        border-radius: 6px 0px 0px 6px;
    }

    .toggle-menu {
        display: flex;
        background: none;
        border: 0px;
        padding: 0px;
        color: #fff;
        font-size: 21px;
    }
}

