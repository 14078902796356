body {
    font-family: 'Poppins', sans-serif;
    background: #272727;
    color: #fff;
    margin: 0;
}

::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #ffc732;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #575757;
  }
  
  scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
  }
  
  scrollbar-track {
    background-color: #f5f5f5;
  }