.se-row {
    padding: 0px 40px 50px 40px;
    display: flex;
    flex-direction: column;
}

.services-left {
    display: flex;
    width: 100%;
    flex: 1.5;
    flex-direction: column;
    justify-content: center;
}

.services-right {
    display: flex;
    width: 100%;
    flex: 1;
}

.services-col {
    display: flex;
    margin: 10px 0px;;
    width: 100%;
}

.services-bg {
    background: #2e2e2e;
    width: 100%;
    border-radius: 20px;
    display: flex;
    padding: 0px 0px;
}

.services-header {
    font-size: 66px;
    display: flex;
    align-items: flex-start;
    margin-right: 30px;
    flex: 1;
}

.services-desc {
    display: flex;
    flex: 8;
    flex-direction: column;
}

.services-desc h4 {
    margin: 0;
    padding: 0;
    font-size: 21px;
    font-weight: 500;
}

.list-item-services {
    margin: 0;
    font-size: 15px;
    font-weight: 300;
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
}

.list-item-services li {
    width: 100%;
    padding: 10px 0px;
    display: flex;
}


.a-right-img {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.a-right-img img {
    width: 50%;
    object-fit: cover;
}


@media screen and (max-width: 768px) {
    .se-row {
        padding: 0px 0px 30px 0px;
        display: flex;
        flex-direction: column;
    }

    .services-col {
        margin: 10px 0px;
    }

    .services-bg {
        flex-direction: column;
        padding: 30px 20px;
    }

    .services-desc {
        margin-top: 20px;
    }
    .services-right {
        margin-top: 30px;
    }
    .a-right-img img {
        width: 100%;
        object-fit: cover;
    }
}
