.i {
    display: flex;
    height: 80vh;
}
.i-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-left-social {
    display: flex;
    padding: 0px 0px;
}

.i-social-a {
    margin-right: 30px;
    margin-bottom: 20px;
    padding: 7px;
    color: #fff;
    border: 1px solid #909090;
    border-radius: 5px;
}

.i-social-a:hover {
    color: #fedd2b;
    border: 1px solid #fedd2b;
}

.i-social-a a {
    color: #fff;
}

.i-left-h {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.i-left-wrapper {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.i-intro {
    font-size: 30px;
    font-weight: 300;
    margin: 0px;
    padding:0px;
}

.i-name {
    margin: 0px;
    padding: 0px;
    font-size: 50px;
    line-height: 54px;
}

.i-title {
    height: 38px;
    overflow: hidden;
    margin-bottom: 20px;
}

.i-title-wrapper {
    height: 100%;
}

.i-title-item {
    height: 37px;
    font-size: 20px;
    font-weight: 500;
    color: #ffc732;
    display: flex;
    align-items: center;
}

.i-desc {
    display: flex;
    border-top: 1px solid #909090;
    padding: 20px 0px;
    font-weight: 300;
}

.i-cv {
    display: flex;
    margin-top: 20px;
}

.i-button {
    padding: 15px 30px;
    cursor: pointer;
    background: #ffc732;
    border: none;
    color: #000;
    border-radius: 8px;
    align-items: center;
    display: flex;
}

.i-icon {
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 15px;
    height: 15px;
}

.i-right {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
}

.i-right-wrapper {
    padding: 0px;
    height: 80%;
}

.i-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 53% 47% 61% 39% / 33% 57% 43% 67% ;
    animation: monkey 10s ease-in-out infinite;
}

@keyframes monkey {
    0%{
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
    25%{
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }   
    50%{
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
    75%{
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }   
    100%{
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
}

@media screen and (max-width: 768px) {
    .i {
        flex-direction: column-reverse;
        height: 100%;
        padding: 0px 20px;
    }

    .i-left-social {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
    }

    .i-left-social a {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 20px;
    }

    .i-social-a {
        margin-right: 0px;
        margin-bottom: 0px;
    }
  
    .i-left-wrapper {
        margin-top: 50px;
        align-items: center;
        padding: 0px;
    }

    .i-left-h {
        width: 100%;
    }

    .i-title {
        width: 100%;
    }

    .i-right {
        align-items: flex-start;
        height: 80%;
        margin-top:20px;
    }

    .i-right-wrapper {
        display: flex;
        height: 80%;
        align-items: center;
        justify-content: center;
    }
    
    .i-img {
        width: 60%;
    }

    .i-intro {
        font-size: 20px;
        font-weight: 300;
        margin: 0px;
        padding: 0px;
    }

    .i-name {
        margin: 0px;
        padding: 0px;
        font-size: 38px;
        line-height: 54px;
    }

    .i-title-item {
        height: 37px;
        font-size: 16px;
        font-weight: 500;
        color: #ffc732;
        display: flex;
        align-items: center;
    }

    .i-desc {
        width: 100%;
        font-size: 15px;
    }
    .i-cv {
        width: 100%;
    }
    
  }

::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #ffc732;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #575757;
  }
  
  scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
  }
  
  scrollbar-track {
    background-color: #f5f5f5;
  }
  